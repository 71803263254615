import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { Nullable } from 'src/types/nullable.type';
import { Node } from 'src/common/interfaces/node.interface';
import { Journey } from '../interfaces/journey.interface';
import { SLIDE_OVER_ANIMATION_DURATION_IN_MS, useSlideOverStore } from 'src/store/slide-over';
import { useCurrentOrganization } from 'src/store/organization';
import { JourneyAliasFormType, ShareLinkFormFactory } from '../components/share/form/link-config-form.factory';
import { JourneyAlias } from '../interfaces/journey/alias.interface';
import objectPath from 'object-path';

export const SHARE_PANEL_INITIAL_ACTIVE_TAB = 'primary-link';
export type SHARE_PANEL_CONFIGURE_TYPE =
  | 'access'
  | 'signatures'
  | 'embed'
  | 'additional-link'
  | 'presentation'
  | 'conversations'
  | 'personalization'
  | 'indexing'
  | 'custom-fields';
const MUX_IMAGE_HOST = 'https://image.mux.com';

interface State {
  sharedJourney: Nullable<Journey>;
  activeAlias: Nullable<JourneyAlias>;
  isNewAlias: boolean;
  isSaving: boolean;

  isConfigureMode: boolean;
  linkConfigTree: SHARE_PANEL_CONFIGURE_TYPE[];
  activeLinkForm: Nullable<JourneyAliasFormType>;
  setSharedJourney: (journey: Nullable<Journey>) => void;
  updateActiveLinkForm: (form: any) => void;
  onJourneyShareOpen: (journey: Journey) => void;
  onJourneyShareClose: () => void;
  pushLinkConfigType: (section: SHARE_PANEL_CONFIGURE_TYPE) => void;
  popLinkConfigType: () => void;
  setIsSaving: (isSaving: boolean) => void;
  setIsNewAlias: (isNewAlias: boolean) => void;
  setActiveAlias: (alias: Nullable<JourneyAlias>) => JourneyAliasFormType;

  aliasName: string;
  setAliasName: (name: string) => void;

  // TODO: divide these into sub-states
  // thumbnail:
  showThumbnailSlider: boolean;
  selectedImageUrl: Nullable<string>;
  selectedThumbnailSourceStep: Nullable<Node>;
  uploadedThumbnailFile: Nullable<File>;
  encodedMuxAsset: Nullable<any>;
  setSelectedThumbnailSourceStep: (step: Nullable<Node>) => void;
  setShowThumbnailSlider: (value: boolean) => void;
  setSelectedImageUrl: (tab: Nullable<string>) => void;
  setUploadedFile: (file: File) => void;
  onSliderUpdate: (time: number) => void;
  onSliderSelectionComplete: () => void;

  resetThumbnailEditor: () => void;
  resetSharePanel: () => void;
}

export const useSharePanelStore = create<State>()(
  devtools(
    (set, get) => ({
      sharedJourney: null,
      activeAlias: null,
      isSaving: false,
      isNewAlias: false,
      isConfigureMode: false,
      linkConfigTree: [],
      activeLinkForm: null,
      selectedImageUrl: '',
      showThumbnailSlider: false,
      uploadedThumbnailFile: null,
      encodedMuxAsset: null,
      selectedThumbnailSourceStep: null,
      aliasName: '',
      setAliasName: (name) => {
        set({
          aliasName: name,
        });
      },
      setSharedJourney: (journey: Nullable<Journey>) => {
        if (journey) {
          get().setSelectedThumbnailSourceStep(objectPath.get(journey, ['steps', 0, 'nodes', 0], null));
        }

        set({ sharedJourney: journey });
      },
      onJourneyShareOpen: (journey: Journey) => {
        useSlideOverStore.getState().openSlideOver('share');
        get().setSharedJourney(journey);
      },
      onJourneyShareClose: () => {
        useSlideOverStore.getState().closeSlideOver();
        const { resetSharePanel, resetThumbnailEditor } = get();
        resetSharePanel();
        resetThumbnailEditor();
        setTimeout(() => {
          set({ sharedJourney: null });
        }, SLIDE_OVER_ANIMATION_DURATION_IN_MS);
      },
      pushLinkConfigType: (section) => {
        set((state) => ({
          linkConfigTree: [...state.linkConfigTree, section],
          isConfigureMode: true,
        }));
      },
      popLinkConfigType: () => {
        const { linkConfigTree } = get();
        const newTree = [...linkConfigTree.slice(0, linkConfigTree.length - 1)];
        set({
          linkConfigTree: newTree,
          isConfigureMode: Boolean(newTree.length),
        });
      },
      setSelectedThumbnailSourceStep: (step) => {
        const muxAsset = step?.video_asset?.mux_asset;
        const encodedMuxAsset = muxAsset?.status === 'ready' ? muxAsset : null;
        set({
          selectedThumbnailSourceStep: step,
          encodedMuxAsset,
        });
      },
      setIsNewAlias: (isNewAlias: boolean) => {
        set({ isNewAlias });
      },
      setIsSaving: (isSaving: boolean) => {
        set({ isSaving });
      },
      /**
       * a helper method to make initial selection of which link to edit.
       * for form updates, use `updateActiveLinkForm`
       * @param activeAlias
       * @returns the active link form
       */
      setActiveAlias: (activeAlias) => {
        const activeLinkForm = ShareLinkFormFactory(
          useCurrentOrganization.getState().currentOrganization,
          get().sharedJourney,
          activeAlias
        );
        set({
          activeAlias,
          activeLinkForm,
        });

        return activeLinkForm;
      },
      updateActiveLinkForm: (form) => {
        const newFormState: any = { ...get().activeLinkForm };
        Object.keys(form).forEach((key) => {
          objectPath.set(newFormState, key, form[key]);
        });

        set((state) => ({
          activeLinkForm: newFormState,
        }));
      },
      setSelectedImageUrl: (url) => {
        set({ selectedImageUrl: url });
      },
      setShowThumbnailSlider: (show) => {
        set({ showThumbnailSlider: show });
      },
      setUploadedFile: (file) => {
        set({ uploadedThumbnailFile: file });
      },
      onSliderUpdate: (time) => {
        const { encodedMuxAsset } = get();
        set({
          selectedImageUrl: encodedMuxAsset
            ? `${MUX_IMAGE_HOST}/${encodedMuxAsset?.public_playback_id}/thumbnail.jpg?time=${time}`
            : null,
        });
      },
      onSliderSelectionComplete: () => {
        set({ showThumbnailSlider: false });
        get().resetThumbnailEditor();
      },

      resetThumbnailEditor: () =>
        set({
          uploadedThumbnailFile: null,
          selectedImageUrl: null,
          selectedThumbnailSourceStep: null,
        }),
      resetSharePanel: () =>
        set({
          selectedImageUrl: null,
          isConfigureMode: false,
          linkConfigTree: [],
        }),
    }),
    { name: 'Share Panel Store' }
  )
);
