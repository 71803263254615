import { useFloating, useHover, useInteractions } from '@floating-ui/react';
import { animated, SpringConfig, useSpring, useSpringRef } from '@react-spring/web';
import classNames from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Nullable } from 'src/types/nullable.type';
import { useRefCallback } from 'src/utils/react/ref-callback.hook';
import { getActiveSectionId } from '../helpers/get-active-section-id';
import { getSuperRect } from '../helpers/get-super-rect';
import { Block, BlockRenderElement, Rect, Section } from '../types';
import { createPropsEqualsChecker } from '../utils/create-props-equals-checker';
import { usePlayerStore } from './player-store';
import { useScrollToSectionById } from './scroll-to-section-by-id.hook';
import { NeuePlayerSectionCardNew } from './neue-player-section-card-new';
import { useReactSpringScrollTo } from '../utils/react-spring-scroll-to.hook';
import { each, onScroll } from '@react-spring/shared';
import { ChevronDownRegularIcon, ChevronRightRegularIcon } from 'src/monet/icons';
import { shallow } from 'zustand/shallow';

export const WIDTH_ANIMATION_CONFIG: SpringConfig = {
  mass: 1,
  tension: 400,
  friction: 40,
};

type SectionCardData = {
  section: Section | any;
  // title: string;
  userProvidedTitle: Nullable<string>;
  // placeholder: boolean;
  blockRenderElements: BlockRenderElement[];
};

const SECTION_BAR_LAYOUT = {
  cardWidth: 272 + 24,
  cardHeight: 42,
  cardGap: 10,
  barMaxHeight: 520,
  barPadding: 4,
};

export const NeuePlayerSectionBarNew = () => {
  const [isFolderOpen, setIsFolderOpen] = useState<Record<string, boolean>>({});

  const {
    layout: { sections: allSections, renderElements, sectionLayoutInfos, innerActualHeight },
    innerAreaHeight,
    canvasScrollPosition,
    isFoldersEnabled,
    sectionDrawerOpen,
  } = usePlayerStore(
    (state) => ({
      layout: state.layout,
      innerAreaHeight: state.innerAreaHeight,
      canvasScrollPosition: state.canvasScrollPosition,
      isFoldersEnabled: state.isFoldersEnabled,
      sectionDrawerOpen: state.sectionDrawerOpen,
    }),
    shallow
  );
  const uniqueIdsRef = useRef(new Set());
  const formattedSectionCardDataRef = useRef<SectionCardData[]>([]);
  const groupedItemsRef = useRef(new Map());
  const setCurrentSectionId = usePlayerStore((state) => state.setCurrentSectionId);
  const currentSectionId = usePlayerStore((state) => state.currentSectionId);
  const setScrollToSectionId = usePlayerStore((state) => state.setScrollToSectionId);
  const scrollToSectionId = usePlayerStore((state) => state.scrollToSectionId);
  const setCurrentSectionIndex = usePlayerStore((state) => state.setCurrentSectionIndex);
  // const [lastScrollDirection, setLastScrollDirection] = React.useState<ScrollDirection>('up');

  const scrollToSectionById = useScrollToSectionById(() => {
    setScrollToSectionId(null);
  });

  // const currentSectionId = allSections[currentSectionIndex]?.id;
  const sections = allSections.filter((section) => !section.hidden && !section.hiddenInNavigation);

  if (groupedItemsRef.current.size === 0) {
    sections.forEach((item) => {
      const folderID = item.folderID;
      if (!groupedItemsRef.current.has(folderID)) {
        groupedItemsRef.current.set(folderID, [item]); // Se não houver nenhum item com o mesmo folderID, crie um novo array com o item
      } else {
        groupedItemsRef.current.get(folderID).push(item); // Se já houver um array para o folderID, adicione o item a esse array
      }
    });
  }

  // useScrollDirection(
  //   playerContentElement,
  //   (direction) => {
  //     if (scrollToSectionId) return;
  //     setLastScrollDirection(direction);
  //   },
  //   2
  // );

  const [hovering, setHovering] = useState(true);
  const { refs, context } = useFloating({
    open: hovering,
    // onOpenChange: setHovering,
  });
  const hover = useHover(context);
  const { getReferenceProps } = useInteractions([hover]);

  const renderElementsMap = useMemo(() => {
    const map = new Map<Block['id'], BlockRenderElement>();
    for (const re of renderElements) {
      if (re.type === 'block') {
        map.set(re.block.id, re);
      }
    }
    return map;
  }, [renderElements]);

  const updateActiveSectionId = useRefCallback(() => {
    if (!innerAreaHeight) return null;
    if (!innerActualHeight) return null;
    if (sections.length === 0) return null;
    const scrollBasedActiveId = getActiveSectionId({
      sections,
      sectionLayoutInfos,
      innerAreaHeight,
      innerActualHeight,
      canvasScrollPosition,
    });
    if (!scrollBasedActiveId) return;
    if (scrollToSectionId) return;
    if (scrollBasedActiveId === currentSectionId) return;
    // console.log('SectionBar: updateActiveSectionId:', 'active section', scrollBasedActiveId);
    setCurrentSectionId(scrollBasedActiveId);
  }, [innerActualHeight, sections, sectionLayoutInfos, canvasScrollPosition, currentSectionId, scrollToSectionId]);

  useEffect(() => {
    updateActiveSectionId();
  }, [canvasScrollPosition, innerActualHeight, sections.length, updateActiveSectionId]);

  React.useEffect(() => {
    if (scrollToSectionId) {
      scrollToSectionById(scrollToSectionId);
    }
  }, [scrollToSectionId]);

  // useEffect(() => {
  //   if (!currentOrganization) return;
  //   if (!journey) return;
  //   if (!currentSectionId) return;
  //   if (initialSectionId) return;
  //   const sectionRelativePath = sections.find((section) => section.id === currentSectionId)?.friendlyPath || '';
  //   const sectionRelativeUrl = generateJourneyNeueEditUrl(currentOrganization, journey, sectionRelativePath, {
  //     relative: true,
  //   });
  //   console.log('SectionBar: update url', sectionRelativeUrl, initialSectionId);
  //   window.history.replaceState({}, '', sectionRelativeUrl);
  // }, [currentSectionId, initialSectionId, currentOrganization, journey]);

  const sectionCardData: SectionCardData[] = useMemo(
    () =>
      sections.map((section) => {
        let userProvidedTitle = section.name;
        const blockRenderElements = section.blocks
          .map((block) => renderElementsMap.get(block.id))
          .filter(Boolean) as BlockRenderElement[];

        return {
          section: groupedItemsRef.current.get(section.folderID),
          userProvidedTitle,
          blockRenderElements,
        };
      }),
    [sections, renderElementsMap]
  );

  const uniqueRawSections = [...new Set(sectionCardData.flatMap((item) => item.section))];
  const sectionsNoFolder = uniqueRawSections.every(({ folderID }) => !folderID);

  if (formattedSectionCardDataRef.current.length === 0) {
    if (isFoldersEnabled && !sectionsNoFolder) {
      sectionCardData.forEach((item) => {
        const uniqueSection =
          (Array.isArray(item.section) &&
            item.section.length > 0 &&
            item.section.filter((sectionItem) => {
              if (!uniqueIdsRef.current.has(sectionItem.id)) {
                uniqueIdsRef.current.add(sectionItem.id);
                return true;
              }
              return false;
            })) ||
          [];

        if (uniqueSection.length > 0) {
          formattedSectionCardDataRef.current.push({
            section: uniqueSection,
            userProvidedTitle: item.userProvidedTitle,
            blockRenderElements: item.blockRenderElements,
          });
        }
      });
    } else {
      sections.forEach((section) => {
        formattedSectionCardDataRef.current.push({
          section: section,
          userProvidedTitle: section.name,
          blockRenderElements: section.blocks
            .map((block) => renderElementsMap.get(block.id))
            .filter(Boolean) as BlockRenderElement[],
        });
      });
    }
  }

  const onSectionCardClick = (id: Section['id']) => {
    const sectionIndex = allSections.findIndex((section) => section.id === id);
    setCurrentSectionIndex(sectionIndex);
    setCurrentSectionId(id);
    setScrollToSectionId(id);
  };

  const api = useSpringRef();
  const [props] = useSpring(
    () => ({
      ref: api,
      from: { width: 64, expandedFraction: 0 },
      to: { width: 280, expandedFraction: 1 },
    }),
    []
  );

  const [sectionDrawerCompletelyClosed, setSectionDrawerCompletelyClosed] = useState(!sectionDrawerOpen);

  const onStartCallback = useRefCallback(() => {
    setSectionDrawerCompletelyClosed(false);
  }, []);

  const onRestCallback = useRefCallback(() => {
    if (!sectionDrawerOpen) {
      setSectionDrawerCompletelyClosed(true);
    }
  }, [sectionDrawerOpen]);

  useEffect(() => {
    if (sectionDrawerOpen) {
      api.start({
        expandedFraction: 1,
        config: WIDTH_ANIMATION_CONFIG,
        onStart: onStartCallback,
      });
    } else {
      api.start({
        expandedFraction: 0,
        config: WIDTH_ANIMATION_CONFIG,
        onRest: onRestCallback,
      });
    }
  }, [sectionDrawerOpen, onStartCallback, onRestCallback]);

  let y = SECTION_BAR_LAYOUT.barPadding;
  const sectionCardRects: Rect[] = useMemo(() => {
    const rects: Rect[] = [];
    for (let i = 0; i < sections.length; i++) {
      const rect = {
        x: 0,
        y,
        width: SECTION_BAR_LAYOUT.cardWidth,
        height: SECTION_BAR_LAYOUT.cardHeight,
      };
      rects.push(rect);
      y += rect.height;
      if (i < sections.length - 1) {
        y += SECTION_BAR_LAYOUT.cardGap;
      }
    }
    return rects;
  }, [sections]);

  let { width: totalWidth, height: totalHeight } = getSuperRect(sectionCardRects);

  const { scrollTo } = useReactSpringScrollTo();

  const scrollCurrentSectionCardIntoView = useRefCallback(() => {
    const currentSectionIndex = sections.findIndex((section) => section.id === currentSectionId);
    if (currentSectionIndex === -1) return;
    const currentSectionRect = sectionCardRects[currentSectionIndex];
    if (!currentSectionRect) return;
    const scrollContainer = refs.reference.current as HTMLDivElement;
    // if the current section isn't completely within the scroll container, scroll it into view
    if (currentSectionRect.y < scrollContainer.scrollTop) {
      scrollTo(scrollContainer, currentSectionRect.y - 10);
    } else if (
      currentSectionRect.y + currentSectionRect.height >
      scrollContainer.scrollTop + scrollContainer.clientHeight
    ) {
      scrollTo(scrollContainer, currentSectionRect.y - scrollContainer.clientHeight + currentSectionRect.height + 10);
    }
  }, [currentSectionId, sections, sectionCardRects, scrollTo]);

  React.useEffect(() => {
    scrollCurrentSectionCardIntoView();
  }, [currentSectionId]);

  const transitionData = useMemo(
    () =>
      formattedSectionCardDataRef.current.map((sectionCardDataItem, i) => {
        return {
          sectionCardData: sectionCardDataItem,
          rect: sectionCardRects[i],
        };
      }),
    [sectionCardRects]
  );

  const containerRef = React.useRef<HTMLDivElement>(null!);

  // Based on code in
  // https://github.com/pmndrs/react-spring/blob/6c72cb9e3789ba64a4e2a503dfce227ee646f3cc/packages/core/src/hooks/useScroll.ts
  // Can't use it as is, because it doesn't apply on scroll effect immediately
  const [scrollValues, scrollValuesApi] = useSpring(
    () => ({
      scrollTopFadeOpacity: 0,
      scrollBottomFadeOpacity: 0,
    }),
    []
  );

  React.useEffect(() => {
    const cleanupScroll = onScroll(
      ({ y }) => {
        const maxContainerScrollTop = containerRef.current.scrollHeight - containerRef.current.clientHeight;
        scrollValuesApi.start({
          scrollTopFadeOpacity: y.current > 24 ? 1 : y.current / 24,
          scrollBottomFadeOpacity:
            y.current < maxContainerScrollTop - 24 ? 1 : (maxContainerScrollTop - y.current) / 24,
          immediate: true,
        });
      },
      { container: containerRef.current }
    );

    return () => {
      /**
       * Stop the springs on unmount.
       */
      each(Object.values(scrollValues), (value) => value.stop());

      cleanupScroll();
    };
  }, []);

  if (sections.length === 0) {
    return null;
  }

  return (
    <animated.div
      className={classNames(
        'pointer-events-auto h-full flex flex-col justify-start transition text-neue-canvas-fg-50 rounded-lg relative',
        'select-auto'
      )}
      style={{
        width: `${(sectionDrawerCompletelyClosed ? 96 : 280) + 24}px`,
      }}
    >
      <div className={classNames('relative flex flex-col flex-1 rounded-lg overflow-hidden isolate justify-center')}>
        <div
          className='relative overflow-y-auto scrollbar-hidden'
          style={{
            width: `${totalWidth}px`,
          }}
          ref={(el) => {
            refs.setReference(el);
            containerRef.current = el!;
          }}
          {...getReferenceProps()}
        >
          <div
            className='relative pt-2'
            style={{
              width: `${totalWidth}px`,
            }}
          >
            {transitionData.map((item) => {
              const {
                sectionCardData: { section, blockRenderElements },
              } = item;

              const showFolderNavigation = isFoldersEnabled && !sectionsNoFolder;
              const isFolder = showFolderNavigation && section.every((s: Section) => s.folderID);

              if (isFolder && !Array.isArray(section)) {
                return null;
              }

              const { folderName, folderID: folderId } = showFolderNavigation ? section[0] : section;
              if (section.length === 1) {
                return (
                  <NeuePlayerSectionCardNew
                    key={section[0].id}
                    sectionDrawerOpen={sectionDrawerOpen}
                    id={section[0].id}
                    title={section[0].name || 'Untitled'}
                    current={section[0].id === currentSectionId}
                    blockRenderElements={blockRenderElements}
                    onCardClick={() => onSectionCardClick(section[0]?.id)}
                  />
                );
              }

              if (!isFolder) {
                return (
                  <NeuePlayerSectionCardNew
                    key={section.id}
                    sectionDrawerOpen={sectionDrawerOpen}
                    id={section.id}
                    title={section.name || 'Untitled'}
                    current={section.id === currentSectionId}
                    blockRenderElements={blockRenderElements}
                    onCardClick={() => onSectionCardClick(section.id)}
                  />
                );
              }

              const isCurrentFolderOpen = isFoldersEnabled && !isFolderOpen[folderId];

              return (
                <div
                  className={classNames(
                    'flex flex-1 flex-col my-2 auto-rows-max w-auto overflow-hidden appearance-none rounded-lg bg-neue-canvas-fg-5 hover:bg-neue-canvas-fg-10 transition-colors text-neue-canvas-fg pb-1',
                    {
                      'ml-2 max-w-[300px]': sectionDrawerOpen,
                      'max-w-[120px]': !sectionDrawerOpen,
                    }
                  )}
                >
                  <div
                    className='flex w-full items-center justify-between py-3 pl-6 pr-4 pb-2 cursor-pointer'
                    onClick={() => {
                      setIsFolderOpen((currentState) => ({
                        ...currentState,
                        [folderId]: !Object.keys(folderId)?.length ? false : !isFolderOpen[folderId],
                      }));
                    }}
                  >
                    <div className='flex flex-1 flex-grow w-full justify-between text-neue-canvas-fg text-neue-journey-small'>
                      <p className='text-nowrap truncate text-inherit'>{folderName || 'New section'}</p>
                      {isCurrentFolderOpen ? (
                        <ChevronDownRegularIcon className='text-inherit' />
                      ) : (
                        <ChevronRightRegularIcon className='text-inherit' />
                      )}
                    </div>
                  </div>
                  {isCurrentFolderOpen &&
                    (section as Section[]).map((currentSection, currentIndex) => {
                      // Compute blockRenderElements for the currentSection
                      const blockRenderElements = currentSection.blocks
                        .map((block: Block) => renderElementsMap.get(block.id))
                        .filter(Boolean) as BlockRenderElement[];

                      return (
                        <NeuePlayerSectionCardNew
                          key={currentSection.id}
                          sectionDrawerOpen={sectionDrawerOpen}
                          id={currentSection.id}
                          title={currentSection.name || 'Untitled'}
                          current={currentSection.id === currentSectionId}
                          blockRenderElements={blockRenderElements}
                          onCardClick={() => onSectionCardClick(currentSection?.id)}
                        />
                      );
                    })}
                </div>
              );
            })}
          </div>
        </div>
        <animated.div
          className='absolute h-12 left-0 top-0 right-0 pointer-events-none bg-gradient-to-b from-neue-canvas-bg to-transparent'
          style={{
            opacity: scrollValues.scrollTopFadeOpacity,
          }}
        ></animated.div>
        <animated.div
          className='absolute h-12 left-0 bottom-0 right-0 pointer-events-none bg-gradient-to-t from-neue-canvas-bg to-transparent'
          style={{
            opacity: scrollValues.scrollBottomFadeOpacity,
          }}
        ></animated.div>
      </div>
    </animated.div>
  );
};

export const MemoizedNeuePlayerSectionBarNew = React.memo(
  NeuePlayerSectionBarNew,
  createPropsEqualsChecker([], 'NeuePlayerSectionBarNew')
);
