import classNames from 'classnames';
import React, { MutableRefObject, useEffect, useRef } from 'react';
import { IconButton } from 'src/common/icon-button';
import { Journey } from 'src/common/interfaces/journey.interface';
import { DeathstarStrongIcon, DoubleChatStrongIcon, EditStrongIcon } from 'src/monet/icons';
import { useSegmentTrackedLink } from 'src/utils/segment-tracked-link.hook';
import { Nullable } from 'src/types/nullable.type';
import { JourneyWatermark } from './journey-watermark';
import { animated, useSpring, useSpringRef } from '@react-spring/web';
import { NeueSharePanelButton } from '../share-panel/button';
import { useCurrentUser } from 'src/store/user';
import { TalkToJourneyButtonPlayer } from '../talk-to-journey/button-player';
import { LayoutMode } from '../types';
import { PlayerChatWidget } from 'src/player/chat/widget';
import { shallow } from 'zustand/shallow';
import { useNeuePlayerUserEventsStore } from './user-events.store';
import { parseAllNodes } from 'src/dashboard/embed/use-journey.hook';
import { AnnotationPlayerWidget } from '../annotation/player-widget';
import { getAliasAnnotation } from '../helpers/transforms/get-alias-annotation';
import { getLocalKey } from 'src/common/helpers/browser-cache';
import { useChatStore } from 'src/common/chat/store';
import { usePlayerStore } from './player-store';
import { getActiveSectionId } from '../helpers/get-active-section-id';
import { functionNoop } from 'src/utils/function/noop';
import { NeuePlayerIndexingPanel } from './indexing-panel';

interface Props {
  isMobile: boolean;
  layoutMode: LayoutMode;
  previewMode: boolean;
  journey: Nullable<Journey>;
  onToggleMapRef: MutableRefObject<(isOpened: boolean) => void>;
  contentContainerElementRef: MutableRefObject<Nullable<HTMLDivElement>>;
}

export const NeuePlayerTopBar = ({
  journey,
  layoutMode,
  previewMode,
  isMobile,
  onToggleMapRef,
  contentContainerElementRef,
}: Props) => {
  const allNodes = parseAllNodes(journey, {});
  const isAnonymousUser = useCurrentUser((state) => !state.currentUser);
  const chatDisabled = useChatStore((state) => state.disabled);
  const containerElementRef = useRef<Nullable<HTMLDivElement>>(null);
  const userEventsFactory = useNeuePlayerUserEventsStore((state) => state.instance, shallow);
  const [activeSectionId, setActiveSectionId] = React.useState<string>(Object.keys(allNodes)[0]);

  const {
    layout: { sections: allSections, sectionLayoutInfos, innerActualHeight },
    innerAreaHeight,
    canvasScrollPosition,
  } = usePlayerStore();

  const sections = allSections.filter((section) => !section.hiddenInNavigation);

  const journeyAnnotation = journey ? getAliasAnnotation(journey) : undefined;

  const editLinkRef = useSegmentTrackedLink('Player Edit Clicked', {
    context: 'player',
  });

  const transitionApi = useSpringRef();
  const [transitionStyles] = useSpring(
    () => ({
      ref: transitionApi,
      from: { y: 0 },
      to: { y: containerElementRef.current?.clientHeight },
    }),
    []
  );

  const isPreviewOnClientSide = typeof journey?.can_edit === 'undefined';

  const isEditingEnabled = isPreviewOnClientSide || journey?.can_edit;

  const hideWatermark = journey?.branding?.hide_journey_upsell || false;

  useEffect(() => {
    if (!innerAreaHeight || !innerActualHeight || sections.length === 0) {
      return;
    }
    const scrollBasedActiveId = getActiveSectionId({
      sections,
      sectionLayoutInfos,
      innerAreaHeight,
      innerActualHeight,
      canvasScrollPosition,
    });
    setActiveSectionId(scrollBasedActiveId);
  }, [canvasScrollPosition, innerActualHeight, innerAreaHeight, sectionLayoutInfos, sections]);

  return (
    <animated.div
      ref={containerElementRef}
      className={classNames('fixed inset-x-0 top-0 z-neue-top-bar', {
        'py-3 px-6 bg-neue-canvas-bg/30 backdrop-blur-md': !isMobile,
        'py-3 px-4 bg-neue-canvas-bg': isMobile,
      })}
      style={transitionStyles}
    >
      <div className='transition-colors text-neue-journey-bg flex place-content-between items-center'>
        <div>
          {!hideWatermark && (
            <JourneyWatermark
              isMobile={isMobile}
              slug={journey?.organization.slug}
              isAnonymousUser={isAnonymousUser}
              canUserEdit={isEditingEnabled}
            />
          )}
        </div>
        <div className='flex gap-2'>
          {!isMobile && (
            <a
              ref={editLinkRef}
              href={journey ? `/journeys/neue-edit/${journey.uuid}` : '#'}
              target='_self'
              rel='noopener noreferrer'
              className={classNames('transition-opacity', {
                'opacity-0 pointer-events-none': !isEditingEnabled,
                'opacity-100': isEditingEnabled,
              })}
            >
              <IconButton classes='!w-8 !h-8'>
                <EditStrongIcon />
              </IconButton>
            </a>
          )}
          {journey && (
            <>
              <PlayerChatWidget
                journey={journey}
                stepUUID={activeSectionId}
                userEventsFactory={userEventsFactory}
                isNeue
                renderOrigin={({ activeChatStack, onClick }) => (
                  <IconButton
                    disabled={chatDisabled}
                    classes={classNames('relative !w-8 !h-8 disabled:opacity-50', {
                      'pointer-events-none': chatDisabled,
                    })}
                    onClick={onClick}
                  >
                    {activeChatStack.messageCount !== 0 ? (
                      <div className='absolute -top-[4px] -right-[4px] z-neue-top-bar-notification'>
                        <div
                          className='flex items-center justify-center rounded-full w-[15px] h-[15px]'
                          style={{ backgroundColor: '#EC5A54' }}
                        >
                          <span className='m-auto' style={{ fontSize: 11 }}>
                            {activeChatStack.messageCount}
                          </span>
                        </div>
                      </div>
                    ) : null}
                    <DoubleChatStrongIcon className='shrink-0' />
                  </IconButton>
                )}
              />
              <AnnotationPlayerWidget
                journeyUUID={journey.uuid}
                creator={journey.creator}
                annotation={journeyAnnotation}
                initialOpen={!getLocalKey(`${journey.uuid}-annotation`)}
              />
            </>
          )}
          {journey && !previewMode && <TalkToJourneyButtonPlayer journey={journey} layoutMode={layoutMode} />}
          {journey && journey.social_sharing && <NeueSharePanelButton journey={journey} platform='player' />}
        </div>
      </div>
    </animated.div>
  );
};
