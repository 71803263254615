import React from 'react';
import { Button } from 'src/common/button';

interface Props {
  onNextClick: () => void;
  onPrevClick: () => void;
  enableNext: boolean;
  enablePrev: boolean;
}

export const NeuePlayerNavigationButtons = (props: Props) => {
  return (
    <div className='flex gap-4 items-center neue-player-navigation-buttons'>
      <Button
        size='small'
        label='Previous'
        variant='primary'
        onClick={props.onPrevClick}
        disabled={!props.enablePrev}
      />
      <Button size='small' label='Next' variant='primary' onClick={props.onNextClick} disabled={!props.enableNext} />
    </div>
  );
};
